.materialSelect{
    font-size: small;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #DEDEDE;
    border: solid 1px black;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 600;
   

}
.material{
    font-size: small;
    height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px black;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 600;
   

}