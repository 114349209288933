.image-item {
    position: relative;
    display: inline-block;
    margin: 10px;
    
}
.gallery .image-item :hover {
    filter: opacity(0.8);
}


.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.3s ease;
}

.image-item:hover .image-overlay {
    opacity: 1;
    /* Show overlay on hover */
}

.icon {
    color: rgba(255, 255, 255, 0.83);
    font-size: 24px;
    margin: 0 10px;
    cursor: pointer;
}

.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}

.gallery .pics {
    transition: all 0.25s ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery .pics:hover {
    filter: opacity(0.8);
}

@media (max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 640px) {
    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}

.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease, visibility 0.4s ease,
        transform 0.2s ease-in-out;
    transform: scale(0);
    overflow: hidden;
}

.modal.open {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
}

.modal.open button.close-button {
    position: fixed;
    top: 10px;
    right: 20px;
    padding: 20px;
}

.modal.open button.next-button {
    position: absolute;
    right: 20px;
    padding: 20px;
}

.modal.open button.previous-button {
    position: absolute;
    left: 20px;
    padding: 20px;
}

.modal img {
    height: 100%;
    max-width: 90%;
    display: block;
    line-height: 0;
    padding: 20px 0 20px;
}